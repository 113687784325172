<template>
	<div class="margin-t-20 width-fill">
		<div class="width-fill bg-fff my-account flex-row-space-between-center">
			<div class="font-size18 font-weight700">更新资料</div>
			<div class="closeBtn" @click="closeBtn">取消</div>
		</div>
		<div :class="status == '1' ? 'imgbox' : 'imgbox1'"></div>
		<div v-if="status == '0'" style="text-align: center;font-size: 15px; margin-bottom: 33px;">{{message}}</div>
		<div style="cursor: pointer; text-align: center; width: 409px; height: 45px;background-color: #3366FF;color:#fff; margin:0 auto;line-height: 45px;" @click="closeBtn1">{{ status == '1' ? '确定': '我知道了'}}</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		components: {},
		data() {
			return {
				url: '',
				logoUrl: '',
				status: '0',
				message: '',
			};
		},
		created() {
			this.logoUrl = this.$store.getters.getLogoUrl ? this.$store.getters.getLogoUrl : this.weblogUrl;
		},
		mounted() {
			console.log(this.$route.query,'查看数据到底传过来没')
			this.message = this.$route.query.message
			this.status= this.$route.query.status
		},
		computed: {
			...mapGetters(['getThemeName', "getLogoUrl"]),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			closeBtn() {
				this.$router.push({
					path: '/businessme/JYTwallet',
					query: {
						title: '交易通'
					}
				})
			},
			closeBtn1() {
				this.$router.push({
					path: '/businessme/JYTRecordList',
					query: {
						title: '交易通变更记录'
					}
				})
			},
		}
	};
</script>

<style  lang="scss" scoped="scoped">
	.imgbox {
		width: 222px;
		height: 222px;
		background: url('../../../assets/u2658.png') no-repeat;
		background-size: 100% 100%;
		margin: 58px auto;
	}
	.imgbox1 {
		width: 222px;
		height: 222px;
		background: url('../../../assets/Changefailed.png') no-repeat;
		background-size: 100% 100%;
		margin: 58px auto;
	}
	.closeBtn {
		text-align: center;
		border: 1px solid #0066FF;
		color: #0066FF;
		width: 98px;
		height: 32px;
		line-height: 32px;
		cursor: pointer;
	}
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}
	
	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}
	
	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}
	
	.result-box {
		margin-left: 66px;
		margin-top: 24px;
		width: 100%;
	}
	
	.pay-center {
		width: 69px;
		height: 22px;
		margin-left: 25px;
	}
	
	.result-message-box {
		width: 196px;
		height: 222px;
		margin-top: 11px;
		margin-left: 47px;
	}
	
	.title-style {
		color: #3FD09E;
		font-size: 20px;
		font-weight: bold;
		line-height: 35px;
	}
	
	.login-t {
		font-weight: 700;
		font-size: 18px;
		height: 80px;
		min-width: 1100px;
		max-width: 1100px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		/*垂直居中*/
		background-color: #ffffff;
	}
	
	.loginh {
		margin-top: 80px;
		background: url('');
		width: 100%;
		height: 570px;
		background-repeat: no-repeat;
		background-size: cover;
		position: absolute;
		left: 0;
		top: 0;
		z-index: -999;
	}
	
	.login-c {
		margin: 0 auto;
		width: 900px;
		height: 253px;
		border-radius: 10px;
		box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.149019607843137);
		background-color: #ffffff;
	}
	
	/* 单选地址 */
	
	.flexUD {
		display: flex;
		align-items: center;
		/*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}
	
	/*  */
	.font-color-E30000 {
		color: #e30000;
	}
	
	.bg-E30000 {
		background-color: #e30000;
		color: #ffffff;
	}
	
	/*  */
	.buttonStyle {
		width: 160px;
		height: 40px;
		background-color: #ffffff;
		border-radius: 2px;
	}
</style>